import React, { useState, useEffect } from 'react';

import { Summary } from './summary';
import { Dropdown } from '../dropdown';
import { Shareholders } from './shareholders';
import { Directors } from './directors';
import { Loader } from '../../components/loader';

import { TUser, TCompany, TDirector, TClient } from '../../types';

import API from '../../services/api';

import './client_info.scss';


export const ClientInfo = ({ client }: { client: TClient }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<TCompany>();
    const [user, setUser] = useState<TUser>();
    const [directors, setDirectors] = useState<TDirector[]>(company?.details?.directors || []);

    const directorsSort = (by: string) => {
        switch (by) {
            case 'Name': setDirectors([...directors.sort((a, b) => ('' + a.name).localeCompare(b.name))]); break;
            case 'Most Recent': setDirectors([...directors.sort((a, b) => new Date(b.appointedAt).getTime() - new Date(a.appointedAt).getTime())]); break;
        }
    }

    useEffect(() => {
        API.getCompany(client.id)
            .then(res => {
                if (res?.details?.directors) setDirectors(res.details.directors);
                setCompany(res);
                if (res?.users.length) return API.getUser(res.users[0]);
            })
            .then((res) => { setUser(res); setLoading(false); })
    }, [client.id]);

    const handleViewChange = (item: string) => {
        const v = views.findIndex(i => i.label === item);
        if (v === undefined) return;
        setView(v);
    }

    const [view, setView] = useState<number>(0);

    const views = [
        {
            name: "shareholders",
            label: "Shareholders (top 10)",
            component: <Shareholders list={company?.details?.shareAllocation || []} />,
        },
        {
            name: "directors",
            label: "Directors",
            component: <Directors list={directors || []} />,
        }
    ];

    return (
        <main className="client_info">
            <Summary company={company} user={user} />

            {loading ? <Loader /> :
                company?.entity && (<>
                    <div className="search_filter">
                        <div style={{ position: "relative" }}>
                            <Dropdown items={views.map(v => v.label)} type="plain" selected={views[view].label} onClick={handleViewChange} />
                        </div>
                        <div style={{ position: "relative" }}>
                            {views[view].name === "directors" &&
                                <Dropdown items={["Most Recent", "Name"]} type="full" selected="Sort by" onClick={directorsSort} />
                            }
                        </div>

                    </div>
                    {
                        views[view].component
                    }
                </>)
            }
        </main >
    );
}