import React from 'react';

import { Months } from '../../types';

import { TDirector } from '../../types';

export const Directors = ({ list }: { list: TDirector[] }) => {

    const formatDate = (d: Date): string => `${d.getDate()} ${Months[d.getMonth()]} ${d.getFullYear()}`;

    return (
        <section className="directors">
            {
                list.map((director, i) =>
                    <div key={i} className="director">
                        <div className="director__name">
                            <div>Full legal name:</div>
                            <div className="director__value">{director.name}</div>
                        </div>
                        <div className="director__name">
                            <div>Status</div>
                            <div className="director__value">{director.status}</div>
                        </div>
                        <div className="director__name">
                            <div>Appointment date</div>
                            <div className="director__value">{formatDate(new Date(director.appointedAt))}</div>
                        </div>
                    </div>
                )
            }
        </section>
    );

}