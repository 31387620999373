import React, { useState, createContext } from 'react';
import { TClient, TBookmark, TSearch } from '../types';
import API from '../services/api';

type TContext = {
    bookmarks: TBookmark[];
    bookmarkGet: Function;
    bookmarkAdd: Function;
    bookmarkDel: Function;
    bookmarkSort: Function;
    bookmarked: Function;

    searches: TSearch[];
    search: Function;
    searchesGet: Function,
    searchesAdd: Function;
    searchesDel: Function;
    searchesSort: Function;

    companies: TClient[];

    client: TClient;
    clientSet: Function;

}

export const Context = createContext<TContext>({
    bookmarks: [],
    bookmarkGet: () => { },
    bookmarkAdd: () => { },
    bookmarkDel: () => { },
    bookmarkSort: () => { },
    bookmarked: () => { },

    searches: [],
    search: () => { },
    searchesGet: () => { },
    searchesAdd: () => { },
    searchesDel: () => { },
    searchesSort: () => { },

    companies: [],

    client: { id: "", name: "" },
    clientSet: () => { },

});

export const ContextProvider = ({ children }: any) => {

    /* Bookmark */
    const [bookmarks, setBookmarks] = useState<TBookmark[]>([]);
    const bookmarkGet = async () => {
        const bookmarks = await API.getBookmarks();
        setBookmarks(bookmarks);
    }
    const bookmarkAdd = async (bookmark: TBookmark) => {
        if (bookmarks.find(b => b.name === bookmark.name) === undefined) {
            await API.addBookmark(bookmark);
            setBookmarks([{ name: bookmark.name, added: new Date(), id: bookmark.id, }, ...bookmarks,]);
        }
    }
    const bookmarkDel = async (id: string) => {
        await API.delBookmark(id);
        setBookmarks(bookmarks.filter((b) => b.id !== id));
    }

    const bookmarkSort = (by: string) => {
        switch (by) {
            case "Name": setBookmarks([...bookmarks.sort((a, b) => ('' + a.name).localeCompare(b.name))]); break;
            case "Recently added": setBookmarks([...bookmarks.sort((a, b) => new Date(b.added).getTime() - new Date(a.added).getTime())]); break;
        }
    }
    const bookmarked = (bookmark: string): boolean => bookmarks.find(b => b.name === bookmark) ? true : false;

    /* Search */
    const [searches, setSearches] = useState<TSearch[]>([]);
    const searchesAdd = async (search: TSearch) => {
        await API.addSearch(search);
        setSearches([{ name: search.name, added: new Date(), id: search.id, }, ...searches,]);
    }

    const searchesGet = async () => {
        const searches = await API.getSearches();
        if (searches) setSearches(searches.sort((a, b) => new Date(b.added).getTime() - new Date(a.added).getTime()));
    }
    const searchesDel = (id: number) => setSearches(searches.filter((_, i) => i !== id));
    const searchesSort = (by: string) => {
        switch (by) {
            case "Name": setSearches([...searches.sort((a, b) => ('' + a.name).localeCompare(b.name))]); break;
            case "Most recent": setSearches([...searches.sort((a, b) => new Date(b.added).getTime() - new Date(a.added).getTime())]); break;
        }
    }

    /* Companies */
    const [companies, setCompanies] = useState<TClient[]>([]);
    const search = async (q: string) => {
        const res = await API.search(q);
        setCompanies(res);
    }

    /* Client */
    const [client, setClient] = useState<TClient>({ name: '', id: '' });
    const clientSet = (client: TClient) => setClient({ ...client });


    const ctx = {
        bookmarks,
        bookmarkGet,
        bookmarkAdd,
        bookmarkDel,
        bookmarkSort,
        bookmarked,

        searches,
        search,
        searchesGet,
        searchesAdd,
        searchesDel,
        searchesSort,

        companies,

        client,
        clientSet,


    };

    return <Context.Provider value={ctx}>{children}</Context.Provider>;

};

export default Context;