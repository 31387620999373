import React, { useState, useEffect } from 'react';

import { Table } from './table';
import { Graph } from './graph';
import { Loader } from '../loader';
import { NotConnected } from '../not_connected';

import API from '../../services/api';

import { TClient, TConnection } from '../../types';

import './health_check.scss';

export const HealthCheck = ({ client }: { client: TClient }) => {

    const [view, setView] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [connections, setConnections] = useState<TConnection[]>([]);

    const toggleView = (v: boolean) => {
        setView(v);
    }

    useEffect(() => {
        API.getConnections(client.id)
            .then((conns) => {
                setConnections(conns.filter(conn => conn.isAccounting));
                setLoading(false);
            })
    }, [client.id]);


    return (
        <>
            <main className="client_hc">

                {loading ? <Loader /> :
                    connections.length && connections[0].isAccounting ?
                        (<>

                            <nav className="view">
                                <div className="view__label">View by:</div>
                                <div className="view__graph">
                                    <button className={view ? "view__btn" : "view__btn view__btn--selected"} onClick={() => toggleView(false)}>Graph</button>
                                </div>
                                <div className="view__table">
                                    <button className={!view ? "view__btn" : "view__btn view__btn--selected"} onClick={() => toggleView(true)}>Table</button>
                                </div>
                            </nav>

                            {view && <Table client={client} />}
                            {!view && <Graph client={client} />}
                        </>)
                        : <NotConnected />
                }
            </main>
        </>
    )
}