import React from 'react';
import renderWidgetChart from './widget-chart.js';
import { TGraphProps } from '../../types';

import './charts.scss';
import './Bar.scss';

const width = 720;
const height = 300;

const Graph: React.FC<TGraphProps> = ({ data, chartId, type, loading }) => {
    const setRef = (element: any): void => {
        if (element) {
            renderWidgetChart({
                type,
                data,
                containerElement: element,
                width,
                height,
            });
        }
    };

    return (
        <div className="chart-wrapper">
            <div
                className={`chart${type === 'bar' ? ' barChart' : ''}`}
                id={`${type}-chart-container-${chartId}`}
                ref={setRef}
            //style={{ width: width + 'px', height: height + 'px', margin: '0px' }}
            />
        </div>
    );
};

export default Graph;