import * as d3 from 'd3';
import * as charts from './common';
import 'typeface-lato';

function renderWidgetChart(options) {
  width = options.width ? options.width : 360;
  height = options.height ? options.height : 300;
  switch (options.type) {
    case 'area':
      return renderWidgetAreaChart(options);
    case 'bar':
      return renderWidgetBarChart(options);
    case 'barline':
      return renderWidgetBarLineChart(options);
    default:
      console.log('No matched chart type.');
      break;
  }
}

// Arrows
const upArrowPath = 'M 0,0 L -6,7 L -2.5,7 L -2.5,14 L 2.5,14 L 2.5,7 L 6,7 Z';
const downArrowPath = 'M 0,14 L -6,7 L -2.5,7 L -2.5,0 L 2.5,0 L 2.5,7 L 6,7 Z';
const noChangePath = 'M -6,4.5 L -6,9.5 L 6,9.5 L 6,4.5 Z';
const arrowHeight = 14;
const arrowWidth = 12;
var width = 360;
var height = 300;

function getArrowPath(curr, prev) {
  if (curr > prev) {
    return upArrowPath;
  } else if (curr < prev) {
    return downArrowPath;
  } else {
    return noChangePath;
  }
}

function getArrowClass(curr, prev) {
  if (curr > prev) {
    return 'up-arrow';
  } else if (curr < prev) {
    return 'down-arrow';
  } else {
    return 'no-change';
  }
}

// Format
function formatInfoValue(value, type) {
  switch (type) {
    case 'count':
      return d3.format(',d')(value);
    case 'percentage':
      return d3.format('.1%')(value / 100);
    case 'dollar':
      return d3.format(',.2f')(value);
    default:
      return d3.format(',d')(value);
  }
}

function formatFocusValue(value, type) {
  switch (type) {
    case 'count':
      return d3.format(',d')(value);
    case 'percentage':
      return d3.format('.1%')(value / 100);
    case 'dollar':
      return d3.format('.3s')(value);
    default:
      return d3.format(',d')(value);
  }
}

function renderWidgetAreaChart(options) {
  let chart = {};
  const { containerElement, data } = options;
  let lastCurrentLabelIndex, prevData, currData;
  let focusPrevMarker, focusCurrMarker, focusCurrValue, axisTicks, focusOpaqueOverlay;

  if (containerElement) {
    containerElement.innerHTML = '';
  }

  const containerClass = 'area-chart-container';
  chart.data = data;

  const infoHeight = height * 0.3;
  const axisTicksHeight = height * 0.1;
  const chartHeight = height * 0.6;

  //const infoMarkerRadius = 4.5;
  const infoPadding = 16;
  const focusPrevMarkerRadius = 4;
  const focusCurrMarkerRadius = 7;

  const x = d3.scaleBand();
  const y = d3.scaleLinear();
  const line = d3
    .line()
    .x((d) => x(d[0]) + x.step() / 2)
    .y((d) => y(d[1]))
    .curve(d3.curveMonotoneX);
  const area = d3
    .area()
    .x((d) => x(d[0]) + x.step() / 2)
    .y0((d) => y(0))
    .y1((d) => y(d[1]))
    .curve(d3.curveMonotoneX);

  //area-chart ns-area-chart
  const container = d3.select(`#${containerElement.id}`).classed('chart area-chart ns-fade-in ns-area-chart', true);
  // remove old svg
  container.selectAll('*').remove();
  const svg = container.append('svg').attr('viewBox', [0, 0, width, height]);

  // Gradient
  const defs = svg.append('defs');
  defs
    .append('linearGradient')
    .attr('id', containerClass + '-gradient-yellow')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.5 },
      { offset: '100%', stopOpacity: 0.25 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .style('stop-opacity', (d) => d.stopOpacity)
    .attr('class', 'chart-past ns-area-chart-secondary');
  defs
    .append('linearGradient')
    .attr('id', containerClass + '-gradient-blue-selected')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.5 },
      { offset: '100%', stopOpacity: 0.25 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .attr('class', 'chart-current ns-area-chart-primary')
    .style('stop-opacity', (d) => d.stopOpacity);
  defs
    .append('linearGradient')
    .attr('id', containerClass + '-gradient-blue-forecast')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.3 },
      { offset: '100%', stopOpacity: 0.1 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .attr('class', 'chart-forecast ns-area-chart-forecast')
    .style('stop-opacity', (d) => d.stopOpacity);

  // Clip paths
  const clipCurrent = defs
    .append('clipPath')
    .attr('id', containerClass + '-ns-area-chart-primary')
    .append('rect')
    .attr('x', 0)
    .attr('y', infoHeight)
    .attr('height', height - infoHeight);
  const clipPredict = defs
    .append('clipPath')
    .attr('id', containerClass + '-ns-area-chart-predict')
    .append('rect')
    .attr('y', infoHeight)
    .attr('height', height - infoHeight);

  const gChart = svg.append('g');
  const gInfo = svg.append('g');
  svg
    .append('rect')
    .attr('x', 0)
    .attr('y', infoHeight)
    .attr('width', width)
    .attr('height', height - infoHeight)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .on('mousemove', function () {
      const xPos = d3.mouse(this)[0];
      const index = Math.max(1, Math.round(xPos / x.step() + 0.5));
      if (chart.selected !== index) {
        chart.selected = index;
        updateInfo(chart.selected);
      }
    });

  // Info
  const infoY1Label = gInfo
    .append('text')
    .attr('text-anchor', 'end')
    .attr('class', 'info-label ns-area-chart-info-label')
    .attr('x', width - infoPadding)
    .attr('y', infoHeight * 0.3)
    .attr('dy', '0.32em');

  // const infoPrevMarker = gInfo
  //   .append('circle')
  //   .attr('class', 'previous-info-marker ns-area-chart-secondary-info-marker')
  //   .attr('cy', infoHeight * 0.3)
  //   .attr('r', infoMarkerRadius);

  const infoPrevValue = gInfo
    .append('text')
    .attr('text-anchor', 'end')
    .attr('class', 'ns-area-chart-info-value info-value info-value-previous')
    .attr('x', width - infoPadding)
    .attr('y', infoHeight * 0.7)
    .attr('dy', '0.32em');

  const infoCurrLabel = gInfo
    .append('text')
    .attr('text-anchor', 'start')
    .attr('class', 'ns-area-chart-info-label info-label ')
    //.attr('x', infoPadding + infoMarkerRadius * 3)
    .attr('x', infoPadding)
    .attr('y', infoHeight * 0.3)
    .attr('dy', '0.32em');

  // const infoCurrMarker = gInfo
  //   .append('circle')
  //   .attr('class', 'current-info-marker ns-area-chart-primary-info-marker')
  //   .attr('cx', infoMarkerRadius + infoPadding)
  //   .attr('cy', infoHeight * 0.3)
  //   .attr('r', infoMarkerRadius);

  const infoCurrValue = gInfo
    .append('text')
    .attr('text-anchor', 'start')
    .attr('class', 'info-value ns-area-chart-info-value info-value-current')
    .attr('x', infoPadding)
    .attr('y', infoHeight * 0.7)
    .attr('dy', '0.32em');

  function updateInfo(index) {
    const isPrevData = prevData[index] !== undefined;
    const isCurrData = currData[index] !== undefined;
    // Info
    let infoY1LabelText;
    if (chart.data.xUnit === 'month') {
      infoY1LabelText = 'This month last year';
    } else if (chart.data.xUnit === 'week') {
      infoY1LabelText = 'This month last year';
    } else if (chart.data.xUnit === 'day') {
      infoY1LabelText = 'Last Week';
    } else if (chart.data.xUnit === 'custom') {
      infoY1LabelText = chart.data.y2Unit;
    }

    infoY1Label.text(infoY1LabelText);

    //const infoY1LabelBBox = infoY1Label.node().getBBox();
    //infoPrevMarker.attr('cx', infoY1LabelBBox.x - infoMarkerRadius * 2);

    const infoPrevValueText =
      (isPrevData &&
        (prevData[index][1] === undefined ? '' : charts.formatInfoValue(prevData[index][1], chart.data.yUnit))) ||
      '';
    infoPrevValue.text(infoPrevValueText);

    let infoCurrLabelText;
    if (lastCurrentLabelIndex !== -1 && index > lastCurrentLabelIndex) {
      infoCurrLabelText = 'Est. Number';
    } else if (chart.data.xUnit === 'month') {
      infoCurrLabelText = 'This month';
    } else if (chart.data.xUnit === 'week') {
      infoCurrLabelText = 'This month';
    } else if (chart.data.xUnit === 'day') {
      infoCurrLabelText = 'This Week';
    } else if (chart.data.xUnit === 'custom') {
      infoCurrLabelText = chart.data.y1Unit;
    }
    infoCurrLabel.text(infoCurrLabelText);

    // if (lastCurrentLabelIndex !== -1 && index > lastCurrentLabelIndex) {
    //   infoCurrMarker.style('stroke-dasharray', 2);
    // } else {
    //   infoCurrMarker.style('stroke-dasharray', null);
    // }

    const infoCurrValueText =
      (isCurrData &&
        (currData[index][1] === undefined ? '' : charts.formatInfoValue(currData[index][1], chart.data.yUnit))) ||
      '';
    infoCurrValue.text(infoCurrValueText);

    // Focus
    if (isPrevData) {
      focusPrevMarker
        .style('display', null)
        .attr('cx', x(prevData[index][0]) + x.step() / 2)
        .attr('cy', y(prevData[index][1]));
    } else {
      focusPrevMarker.style('display', 'none');
    }

    if (isCurrData) {
      focusCurrMarker
        .style('display', null)
        .attr('cx', x(currData[index][0]) + x.step() / 2)
        .attr('cy', y(currData[index][1]));
      const focusCurrValueText = charts.formatFocusValue(currData[index][1], chart.data.yUnit);
      focusCurrValue
        .style('display', null)
        .attr('x', x(currData[index][0]) + x.step() / 2)
        .attr('y', y(currData[index][1]))
        .attr('dy', -focusCurrMarkerRadius * 2)
        .text(focusCurrValueText);
    } else {
      focusCurrMarker.style('display', 'none');
      focusCurrValue.style('display', 'none');
    }

    if (isPrevData) {
      focusOpaqueOverlay.style('display', null).attr('x', x(prevData[index][0]));
    } else if (isCurrData) {
      focusOpaqueOverlay.style('display', null).attr('x', x(currData[index][0]));
    } else {
      focusOpaqueOverlay.style('display', 'none');
    }

    // Ticks
    axisTicks.classed('selected', (d, i) => i === index);
  }

  chart.updateData = function (newData) {
    chart.data = newData;
    const xDomain = [].concat(
      ['START'],
      chart.data.labels.map((label) => (label)),
      ['END']
    );
    gChart.selectAll('*').remove();
    chart.selected = null;
    lastCurrentLabelIndex = -1;
    // Update scales
    x.domain(xDomain).range([-width / chart.data.labels.length, width + width / chart.data.labels.length]);

    const yMin = d3.min(chart.data.series, (d) => d3.min(d.data));
    const yMax = d3.max(chart.data.series, (d) => d3.max(d.data));
    y.domain([0, yMax]).clamp(true);

    const yBottom = height - axisTicksHeight;
    const yTop = height - axisTicksHeight - chartHeight;
    const yBottomNegative = height - axisTicksHeight - chartHeight * 0.05;
    const yTopNegative = height - axisTicksHeight - chartHeight * 1.2;
    if (yMin === yMax) {
      if (yMin > 0) {
        y.domain([0, yMax]).range([yBottom, yTop]);
      } else if (yMin < 0) {
        y.domain([yMin, -yMin]).range([yBottomNegative, yTopNegative]);
      } else {
        y.domain([yMin, yMax]).range([yBottom, yBottom]);
      }
    } else if (yMin >= 0) {
      y.domain([0, yMax]).range([yBottom, yTop]);
    } else if (yMax <= 0) {
      const absMax = Math.max(-yMin, yMax);
      y.domain([-absMax, absMax]).range([yBottomNegative, yTopNegative]);
    } else {
      y.domain([yMin, yMax]).range([yBottomNegative, yTop]);
    }

    // Zero line
    gChart
      .append('line')
      .attr('class', 'zero-line ns-area-chart-zero-line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', y(0))
      .attr('y2', y(0));

    // Previous
    prevData = d3.zip(xDomain, chart.data.series.find((d) => d.key === 'previous').data);
    gChart
      .append('path')
      .attr('class', 'previous-area')
      .datum(prevData)
      .attr('d', area)
      .attr('fill', 'url(#' + containerClass + '-gradient-yellow)');
    gChart.append('path').attr('class', 'previous-line ns-area-chart-secondary-line').datum(prevData).attr('d', line);

    focusPrevMarker = gChart
      .append('circle')
      .attr('class', 'focus-previous-marker ns-area-chart-focus-secondary-marker')
      .attr('r', focusPrevMarkerRadius);

    // Current
    lastCurrentLabelIndex = xDomain.indexOf(chart.data.lastCurrentLabel);
    currData = d3.zip(xDomain, chart.data.series.find((d) => d.key === 'current').data);

    const gCurrent = gChart.append('g');

    gCurrent
      .append('path')
      .attr('class', 'current-area')
      .datum(currData)
      .attr('d', area)
      .attr('fill', 'url(#' + containerClass + '-gradient-blue-selected)');
    gCurrent.append('path').attr('class', 'current-line ns-area-chart-primary-line').datum(currData).attr('d', line);

    // Prediction
    if (lastCurrentLabelIndex !== -1) {
      const clipStartX = x(xDomain[lastCurrentLabelIndex]) + x.step() / 2;
      clipCurrent.attr('width', clipStartX);
      clipPredict.attr('x', clipStartX).attr('width', width - clipStartX);

      gCurrent.attr('clip-path', 'url(#' + containerClass + '-ns-area-chart-primary)');

      const gPredict = gChart.append('g').attr('clip-path', 'url(#' + containerClass + '-ns-area-chart-predict)');

      gPredict
        .append('path')
        .attr('class', 'predict-area')
        .datum(currData)
        .attr('d', area)
        .attr('fill', 'url(#' + containerClass + '-gradient-blue-forecast)');
      gPredict.append('path').attr('class', 'predict-line ns-area-chart-forecast-line').datum(currData).attr('d', line);
    }

    focusOpaqueOverlay = gChart
      .append('rect')
      .attr('class', 'focus-opaque-overlay ns-area-chart-focus-opaque-overlay')
      .attr('width', x.bandwidth())
      .attr('y', height - axisTicksHeight - chartHeight)
      .attr('height', chartHeight);

    // Axis ticks
    gChart
      .append('g')
      .selectAll('.tick-line')
      .data(xDomain)
      .join('line')
      .attr('class', 'tick-line ns-area-chart-tick-line')
      .attr('x1', (d) => x(d))
      .attr('x2', (d) => x(d))
      .attr('y1', y.range()[0])
      .attr('y2', y.range()[1]);

    // Prediction + Current
    if (lastCurrentLabelIndex !== -1) {
      gChart
        .append('line')
        .attr('class', 'current-divider-line ns-area-chart-current-divider-line')
        .datum(currData[lastCurrentLabelIndex])
        .attr('x1', (d) => x(d[0]) + x.step() / 2)
        .attr('x2', (d) => x(d[0]) + x.step() / 2)
        .attr('y1', y(0))
        .attr('y2', (d) => y(d[1]));
    }

    focusCurrMarker = gChart
      .append('circle')
      .attr('class', 'focus-current-marker ns-area-chart-focus-primary-marker')
      .attr('r', focusCurrMarkerRadius);

    focusCurrValue = gChart
      .append('text')
      .attr('class', 'focus-current-value ns-area-chart-focus-current-value')
      .attr('text-anchor', 'middle');

    // Axis tick values
    gChart
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height - axisTicksHeight + 1)
      .attr('y2', height - axisTicksHeight + 1)
      .attr('class', 'axis-line ns-area-chart-axis-line');

    axisTicks = gChart
      .append('g')
      .selectAll('.tick-value')
      .data(xDomain)
      .join('text')
      .attr('class', 'tick-value ns-area-chart-tick-value')
      .attr('text-anchor', 'middle')
      .attr('x', (d) => x(d) + x.step() / 2)
      .attr('y', height - axisTicksHeight / 2)
      .attr('dy', '0.32em')
      .text((d) => d);

    chart.selected =
      lastCurrentLabelIndex !== -1
        ? lastCurrentLabelIndex
        : currData.length === xDomain.length
          ? currData.length - 2
          : currData.length - 1;
    updateInfo(chart.selected);
  };

  chart.destroy = function () {
    d3.select(`#${containerElement.id}`).classed('widget-chart widget-area-chart', false).selectAll('*').remove();
    chart = null;
  };

  chart.updateData(chart.data);

  return chart;
}

function renderWidgetBarChart(options) {
  const { containerElement, data } = options;

  let chart = {};
  let barRectY1, barRectY2, axisTicks;
  if (containerElement) {
    containerElement.innerHTML = '';
  }

  chart.data = data;

  const infoHeight = height * 0.2;
  const axisTicksHeight = height * 0.1;
  const chartHeight = height * 0.6;

  const infoMarkerRadius = 5.5;
  const infoPadding = 16;

  const x = d3.scaleBand().range([0, width]).paddingOuter(0.02).paddingInner(0.04);
  const y = d3.scaleLinear().range([height - axisTicksHeight, height - chartHeight - axisTicksHeight]);

  const container = d3.select(`#${containerElement.id}`).classed('chart barChart', true);
  // Remove old svg
  container.selectAll('*').remove();
  const svg = container.append('svg').attr('viewBox', [0, 0, width, height]);

  const defs = svg.append('defs');
  // Gradient
  defs
    .append('linearGradient')
    .attr('id', containerElement.id + '-gradient-yellow-selected')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.75 },
      { offset: '100%', stopOpacity: 0.5 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .style('stop-opacity', (d) => d.stopOpacity)
    .attr('class', 'area-chart-past barChart-secondary');
  defs
    .append('linearGradient')
    .attr('id', containerElement.id + '-gradient-yellow-inactive')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.4 },
      { offset: '100%', stopOpacity: 0.15 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .style('stop-opacity', (d) => d.stopOpacity)
    .attr('class', 'area-chart-past barChart-secondary');
  defs
    .append('linearGradient')
    .attr('id', containerElement.id + '-gradient-blue-selected')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.75 },
      { offset: '100%', stopOpacity: 0.5 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .style('stop-opacity', (d) => d.stopOpacity)
    .attr('class', 'area-chart-current barChart-primary');
  defs
    .append('linearGradient')
    .attr('id', containerElement.id + '-gradient-blue-inactive')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.4 },
      { offset: '100%', stopOpacity: 0.15 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .style('stop-opacity', (d) => d.stopOpacity)
    .attr('class', 'area-chart-current barChart-primary');

  const gChart = svg.append('g');
  const gInfo = svg.append('g');
  svg
    .append('rect')
    .attr('x', 0)
    .attr('y', infoHeight)
    .attr('width', width)
    .attr('height', height - infoHeight)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .on('mousemove', function () {
      const xPos = Math.max(0, d3.mouse(containerElement)[0]);
      const index = Math.floor(xPos / x.step());
      if (chart.selected !== index) {
        chart.selected = index;
        updateInfo(chart.selected);
      }
    });

  // Info
  gInfo
    .append('text')
    .attr('text-anchor', 'start')
    .attr('class', 'info-label barChart-info-label')
    .attr('x', infoPadding + infoMarkerRadius * 3)
    .attr('y', infoHeight * 0.3)
    .attr('dy', '0.32em')
    .text(chart.data.y1Unit);

  gInfo
    .append('circle')
    .attr('class', 'y1-info-marker barChart-primary-info-marker')
    .attr('cx', infoMarkerRadius + infoPadding)
    .attr('cy', infoHeight * 0.3)
    .attr('r', infoMarkerRadius)
    .attr('fill', 'url(#' + containerElement.id + '-gradient-blue-selected)');

  const infoY1Value = gInfo
    .append('text')
    .attr('text-anchor', 'start')
    .attr('class', 'info-value barChart-info-value')
    .attr('x', infoPadding)
    .attr('y', infoHeight * 0.7)
    .attr('dy', '0.32em');

  let infoY1Arrow;
  if (chart.data.y1Arrow === true) {
    infoY1Arrow = gInfo
      .append('g')
      .attr('transform', `${infoPadding + arrowWidth / 2},${infoHeight * 0.7 - arrowHeight / 2}`)
      .append('path');

    infoY1Value.attr('x', infoPadding + arrowWidth * 1.5);
  }

  const infoY1Label = gInfo
    .append('text')
    .attr('text-anchor', 'end')
    .attr('class', 'info-label barChart-info-label')
    .attr('x', width - infoPadding)
    .attr('y', infoHeight * 0.3)
    .attr('dy', '0.32em')
    .text(chart.data.y2Unit);

  gInfo
    .append('circle')
    .attr('class', 'y2-info-marker barChart-secondary-info-marker')
    .attr('cy', infoHeight * 0.3)
    .attr('r', infoMarkerRadius)
    .attr('cx', () => infoY1Label.node().getBBox().x - infoMarkerRadius * 2)
    .attr('fill', 'url(#' + containerElement.id + '-gradient-yellow-selected)');

  const infoY2Value = gInfo
    .append('text')
    .attr('text-anchor', 'end')
    .attr('class', 'info-value barChart-info-value')
    .attr('x', width - infoPadding)
    .attr('y', infoHeight * 0.7)
    .attr('dy', '0.32em');

  let infoY2Arrow;
  if (chart.data.y2Arrow === true) {
    infoY2Arrow = gInfo.append('g').append('path').attr('d', upArrowPath);
  }

  function updateInfo(index) {
    // Info
    const y1Curr = chart.data.series[0].data[index];
    const y1Prev = index === 0 ? y1Curr : chart.data.series[0].data[index - 1];
    const infoY1ValueText = y1Curr === undefined ? '' : formatInfoValue(y1Curr, chart.data.yUnit);
    infoY1Value.text(infoY1ValueText);

    if (chart.data.y1Arrow === true) {
      infoY1Arrow.attr('d', getArrowPath(y1Curr, y1Prev)).attr('class', getArrowClass(y1Curr, y1Prev));
    }

    const y2Curr = chart.data.series[1].data[index];
    const y2Prev = index === 0 ? y2Curr : chart.data.series[1].data[index - 1];
    const infoY2ValueText = y2Curr === undefined ? '' : formatInfoValue(y2Curr, chart.data.yUnit);
    infoY2Value.text(infoY2ValueText);

    if (chart.data.y2Arrow === true) {
      infoY2Arrow
        .attr('d', getArrowPath(y2Curr, y2Prev))
        .attr('class', getArrowClass(y2Curr, y2Prev))
        .each(function () {
          d3.select(this.parentNode).attr(
            'transform',
            () => `${infoY2Value.node().getBBox().x - arrowWidth},${infoHeight * 0.7 - arrowHeight / 2}`
          );
        });
    }

    // Focus
    barRectY1.attr('fill', (d, i) =>
      i === index
        ? 'url(#' + containerElement.id + '-gradient-blue-selected)'
        : 'url(#' + containerElement.id + '-gradient-blue-inactive)'
    );
    barRectY2.attr('fill', (d, i) =>
      i === index
        ? 'url(#' + containerElement.id + '-gradient-yellow-selected)'
        : 'url(#' + containerElement.id + '-gradient-yellow-inactive)'
    );

    // Ticks
    axisTicks.classed('selected', (d, i) => i === index);
  }

  chart.updateData = function (newData) {
    chart.data = newData;
    const xDomain = chart.data.labels;

    gChart.selectAll('*').remove();
    chart.selected = null;

    // Calculate stack layout
    chart.stackedData = d3
      .stack()
      .keys(chart.data.series.map((d) => d.key))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)(
        chart.data.labels.map((l, i) => {
          const d = {};
          d.x = l;
          chart.data.series.forEach((s) => {
            d[s.key] = s.data[i];
          });
          return d;
        })
      );

    // Update scales
    x.domain(xDomain);
    const yMax = d3.max(chart.stackedData[1], (d) => d[1]);
    y.domain([0, yMax]);

    // Update clip paths
    defs
      .selectAll('clipPath')
      .data(chart.stackedData[1])
      .join('clipPath')
      .attr('id', (d, i) => containerElement.id + '-widget-bar-chart-' + i)
      .append('rect')
      .attr('x', (d) => x(d.data.x))
      .attr('width', x.bandwidth())
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(0) - y(d[1]))
      .attr('rx', 3);

    // Bars
    const barGroup = gChart
      .selectAll('.bar-group')
      .data(chart.stackedData)
      .join('g')
      .attr('class', (d, i) => `bar-group y${i + 1}-bar-group`)

    barGroup
      .selectAll('.bar-rect')
      .data((d) => d)
      .join('rect')
      .attr('class', 'bar-rect')
      .attr('x', (d) => x(d.data.x))
      .attr('width', x.bandwidth())
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(d[0]) - y(d[1]));

    barRectY1 = barGroup.filter((d, i) => i === 0).selectAll('.bar-rect');

    barRectY2 = barGroup
      .filter((d, i) => i === 1)
      .selectAll('.bar-rect')
      .attr('clip-path', (d, i) => {
        return 'url(#' + containerElement.id + '-widget-bar-chart-' + i + ')';
      });

    // Axis tick values
    gChart
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height - axisTicksHeight + 1)
      .attr('y2', height - axisTicksHeight + 1)
      .attr('class', 'axis-line barChart-axis-line');

    axisTicks = gChart
      .append('g')
      .selectAll('.tick-value')
      .data(xDomain)
      .join('text')
      .attr('class', 'tick-value barChart-tick-value')
      .attr('text-anchor', 'middle')
      .attr('x', (d) => x(d) + x.bandwidth() / 2)
      .attr('y', height - axisTicksHeight / 2)
      .attr('dy', '0.32em')
      .text((d) => d);

    chart.selected = xDomain.length - 1;
    updateInfo(chart.selected);
  };

  chart.updateData(chart.data);

  return chart;
}

function renderWidgetBarLineChart(options) {
  let chart = {};
  let lineData;
  let focusLineMarker, focusBarValue, barRect, axisTicks, focusOpaqueOverlay;

  const containerId = options.containerElement.id;
  chart.data = options.data;

  if (options.containerElement) {
    options.containerElement.innerHTML = '';
  }

  const infoHeight = height * 0.2;
  const axisTicksHeight = height * 0.1;
  const chartHeight = height * 0.5;

  const infoMarkerRadius = 5.5;
  const infoPadding = 16;
  const focusLineMarkerRadius = 7;

  const x = d3.scaleBand().paddingOuter(0.02).paddingInner(0.04);
  const yLine = d3.scaleLinear().range([height - axisTicksHeight, height - chartHeight - axisTicksHeight]);
  const yBar = d3.scaleLinear().range([height - axisTicksHeight, height - chartHeight / 2 - axisTicksHeight]);
  const line = d3
    .line()
    .x((d) => x(d[0]) + x.bandwidth() / 2)
    .y((d) => yLine(d[1]))
    .curve(d3.curveMonotoneX);
  const area = d3
    .area()
    .x((d) => x(d[0]) + x.bandwidth() / 2)
    .y0(yLine(0))
    .y1((d) => yLine(d[1]))
    .curve(d3.curveMonotoneX);

  const container = d3.select(`#${containerId}`).classed('chart widget-bar-line-chart', true);
  const svg = container.append('svg').attr('viewBox', [0, 0, width, height]);
  const defs = svg.append('defs');

  // Gradient
  defs
    .append('linearGradient')
    .attr('id', containerId + '-gradient-yellow')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.3 },
      { offset: '100%', stopOpacity: 0.1 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .attr('class', 'chart-past ns-area-bar-chart-secondary')
    // .style('stop-color', (d) => d.stopColor)
    .style('stop-opacity', (d) => d.stopOpacity);
  defs
    .append('linearGradient')
    .attr('id', containerId + '-gradient-blue-selected')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.5 },
      { offset: '100%', stopOpacity: 0.25 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .attr('class', 'chart-current ns-area-bar-chart-primary')
    // .style('stop-color', (d) => d.stopColor)
    .style('stop-opacity', (d) => d.stopOpacity);
  defs
    .append('linearGradient')
    .attr('id', containerId + '-gradient-blue-inactive')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data([
      { offset: '0%', stopOpacity: 0.3 },
      { offset: '100%', stopOpacity: 0.1 },
    ])
    .join('stop')
    .attr('offset', (d) => d.offset)
    .attr('class', 'chart-current ns-area-bar-chart-primary')
    // .style('stop-color', (d) => d.stopColor)
    .style('stop-opacity', (d) => d.stopOpacity);

  const gChart = svg.append('g');
  const gInfo = svg.append('g');
  svg
    .append('rect')
    .attr('x', 0)
    .attr('y', infoHeight)
    .attr('width', width)
    .attr('height', height - infoHeight)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .on('mousemove', function () {
      const xPos = d3.mouse(this)[0];
      const index = Math.max(1, Math.round(xPos / x.step() + 0.5));
      if (chart.selected !== index) {
        chart.selected = index;
        updateInfo(chart.selected);
      }
    });

  // Info
  gInfo
    .append('text')
    .attr('text-anchor', 'start')
    .attr('class', 'info-label')
    .attr('x', infoPadding + infoMarkerRadius * 3)
    .attr('y', infoHeight * 0.3)
    .attr('dy', '0.32em')
    .text(chart.data.y1Unit);

  gInfo
    .append('circle')
    .attr('class', 'y1-info-marker barChart-primary-info-marker')
    .attr('cx', infoMarkerRadius + infoPadding)
    .attr('cy', infoHeight * 0.3)
    .attr('r', infoMarkerRadius)
    .attr('fill', 'url(#' + containerId + '-gradient-blue-selected)');

  const infoY1Value = gInfo
    .append('text')
    .attr('text-anchor', 'start')
    .attr('class', 'info-value')
    .attr('x', infoPadding)
    .attr('y', infoHeight * 0.7)
    .attr('dy', '0.32em');

  let infoY1Arrow;
  if (chart.data.y1Arrow === true) {
    infoY1Arrow = gInfo
      .append('g')
      .attr('transform', `${infoPadding + arrowWidth / 2},${infoHeight * 0.7 - arrowHeight / 2}`)
      .append('path');

    infoY1Value.attr('x', infoPadding + arrowWidth * 1.5);
  }

  const infoY1Label = gInfo
    .append('text')
    .attr('text-anchor', 'end')
    .attr('class', 'info-label ns-area-chart-info-label')
    .attr('x', width - infoPadding)
    .attr('y', infoHeight * 0.3)
    .attr('dy', '0.32em')
    .text(chart.data.y2Unit);

  gInfo
    .append('circle')
    .attr('class', 'y2-info-marker barChart-primary-info-marker')
    .attr('cy', infoHeight * 0.3)
    .attr('r', infoMarkerRadius)
    .attr('cx', () => infoY1Label.node().getBBox().x - infoMarkerRadius * 2)
    .attr('fill', 'url(#' + containerId + '-gradient-yellow)');

  const infoY2Value = gInfo
    .append('text')
    .attr('text-anchor', 'end')
    .attr('class', 'info-value')
    .attr('x', width - infoPadding)
    .attr('y', infoHeight * 0.7)
    .attr('dy', '0.32em');

  let infoY2Arrow;
  if (chart.data.y2Arrow === true) {
    infoY2Arrow = gInfo.append('g').append('path').attr('d', upArrowPath);
  }

  function updateInfo(index) {
    const y1Curr = chart.data.series[0].data[index - 1];
    const y1Prev = index === 1 ? y1Curr : chart.data.series[0].data[index - 2];
    infoY1Value.text(y1Curr === undefined ? '' : formatInfoValue(y1Curr, chart.data.yUnit1));

    if (chart.data.y1Arrow === true) {
      infoY1Arrow.attr('d', getArrowPath(y1Curr, y1Prev)).attr('class', getArrowClass(y1Curr, y1Prev));
    }

    // Line
    const y2Curr = chart.data.series[1].data[index];
    const y2Prev = index === 1 ? y2Curr : chart.data.series[1].data[index - 1];
    infoY2Value.text(y2Curr === undefined ? '' : formatInfoValue(y2Curr, chart.data.yUnit2));

    if (chart.data.y2Arrow === true) {
      infoY2Arrow
        .attr('d', getArrowPath(y2Curr, y2Prev))
        .attr('class', getArrowClass(y2Curr, y2Prev))
        .each(function () {
          d3.select(this.parentNode).attr(
            'transform',
            () => `${infoY2Value.node().getBBox().x - arrowWidth},${infoHeight * 0.7 - arrowHeight / 2}`
          );
        });
    }

    // Bar
    barRect.attr('fill', (d, i) =>
      i + 1 === chart.selected
        ? 'url(#' + containerId + '-gradient-blue-selected)'
        : 'url(#' + containerId + '-gradient-blue-inactive)'
    );

    // Focus
    focusLineMarker.attr('cx', x(lineData[index][0]) + x.bandwidth() / 2).attr('cy', yLine(lineData[index][1]));

    const focusBarValueText = formatFocusValue(y1Curr, chart.data.yUnit1);

    let focusBarValueHeight = yBar(y1Curr);
    if (y1Curr === 0) {
      focusBarValueHeight = height - axisTicksHeight;
    }

    focusBarValue
      .attr('x', x(lineData[index][0]) + x.bandwidth() / 2)
      .attr('y', focusBarValueHeight)
      .attr('dy', '-0.69em')
      .text(focusBarValueText);

    focusOpaqueOverlay.attr('x', x(lineData[index][0]));

    // Ticks
    axisTicks.classed('selected', (d, i) => i === index);
  }

  chart.updateData = function (newData) {
    chart.data = newData;
    const xDomain = [].concat(
      ['START'],
      chart.data.labels.map((label) => (label)),
      ['END']
    );
    gChart.selectAll('*').remove();
    chart.selected = null;

    // Update scales
    x.domain(xDomain).range([-width / chart.data.labels.length, width + width / chart.data.labels.length]);
    yBar.domain([0, d3.max(chart.data.series[0].data)]);
    yLine.domain([0, d3.max(chart.data.series[1].data)]);

    // Update clip paths
    defs
      .selectAll('clipPath')
      .data(chart.data.series[0].data)
      .join('clipPath')
      .attr('id', (d, i) => containerId + '-widget-bar-line-chart-' + i)
      .append('rect')
      .attr('x', (d, i) => x(xDomain[i + 1]))
      .attr('width', x.bandwidth())
      .attr('y', (d) => yBar(d))
      .attr('height', (d) => yBar(0) - yBar(d))
      .attr('rx', 3);

    // Line
    lineData = d3.zip(xDomain, chart.data.series[1].data);
    gChart
      .append('path')
      .attr('class', 'y2-area')
      .datum(lineData)
      .attr('d', area)
      .attr('fill', 'url(#' + containerId + '-gradient-yellow)');
    gChart.append('path').attr('class', 'y2-line').datum(lineData).attr('d', line);

    focusOpaqueOverlay = gChart
      .append('rect')
      .attr('class', 'focus-opaque-overlay')
      .attr('width', x.bandwidth())
      .attr('y', height - axisTicksHeight - chartHeight)
      .attr('height', chartHeight);

    focusLineMarker = gChart.append('circle').attr('class', 'focus-y2-marker').attr('r', focusLineMarkerRadius);

    focusBarValue = gChart.append('text').attr('class', 'focus-y1-value').attr('text-anchor', 'middle');

    // Bars
    barRect = gChart
      .append('g')
      .selectAll('.bar-rect')
      .data(chart.data.series[0].data)
      .join('rect')
      .attr('class', 'bar-rect')
      .attr('clip-path', (d, i) => {
        return 'url(#' + containerId + '-widget-bar-line-chart-' + i + ')';
      })
      .attr('x', (d, i) => x(xDomain[i + 1]))
      .attr('width', x.bandwidth())
      .attr('y', (d) => yBar(d))
      .attr('height', (d) => yBar(0) - yBar(d))
      .attr('fill', 'url(#' + containerId + '-gradient-blue-inactive)');

    // Axis tick values
    gChart
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height - axisTicksHeight + 1)
      .attr('y2', height - axisTicksHeight + 1)
      .attr('class', 'axis-line');

    axisTicks = gChart
      .append('g')
      .selectAll('.tick-value')
      .data(xDomain)
      .join('text')
      .attr('class', 'tick-value')
      .attr('text-anchor', 'middle')
      .attr('x', (d) => x(d) + x.bandwidth() / 2)
      .attr('y', height - axisTicksHeight / 2)
      .attr('dy', '0.32em')
      .text((d) => d);

    chart.selected = lineData.length === xDomain.length ? lineData.length - 2 : lineData.length - 1;
    updateInfo(chart.selected);
  };

  chart.updateData(chart.data);

  return chart;
}

export default renderWidgetChart;
