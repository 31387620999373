import React, { useState } from 'react';

import './tabs.scss';

type TProps = {
    labels: string[];
    onChange: Function;
}


export const Tabs = (props:TProps) => {

    const [ selected, setSelected ] = useState<number>(0);

    return (
        <nav className="tabs">
            <ul className="tabs__list">
                {
                    props.labels.map((label, idx) => 
                        <li key={idx} className={selected === idx ? "tabs__item tabs__item--selected" : "tabs__item"}><span onClick={() => {setSelected(idx); props.onChange(idx);}}>{label}</span></li>
                    )
                }
            </ul>
        </nav>
    )
}