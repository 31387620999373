import React, { useEffect, useState } from 'react'

import { TSummary, TClient } from '../../types';
import { Loader } from '../loader';

import API from '../../services/api';

export const Table = ({ client }: { client: TClient }) => {

    const [summary, setSummary] = useState<TSummary>();

    useEffect(() => {

        API.getSummary(client.id)
            .then(res => setSummary(res));
    }, [client.id])

    if (!summary) return <Loader />

    return (
        <table className="hc_table">
            <thead>
                <tr>
                    <th>Metric</th>
                    <th>Trend</th>
                    <th>{summary.labels.current}</th>
                    <th>{summary.labels.previous}</th>
                    <th>Change</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Revenue</td>
                    <td className="hc_table__trend"><img src={`/images/trend_${summary.revenue.trend}.png`} alt="Trend Up" /></td>
                    <td>{summary.revenue.current}</td>
                    <td>{summary.revenue.previous}</td>
                    <td>{summary.revenue.change}</td>
                </tr>
                <tr>
                    <td>Expenses</td>
                    <td className="hc_table__trend"><img src={`/images/trend_${summary.expenses.trend}.png`} alt="Trend Up" /></td>
                    <td>{summary.expenses.current}</td>
                    <td>{summary.expenses.previous}</td>
                    <td>{summary.expenses.change}</td>
                </tr>
                <tr>
                    <td>Gross profit margin</td>
                    <td className="hc_table__trend"><img src={`/images/trend_${summary.grossProfitMargin.trend}.png`} alt="Trend Down" /></td>
                    <td>{summary.grossProfitMargin.current}%</td>
                    <td>{summary.grossProfitMargin.previous}%</td>
                    <td>{summary.grossProfitMargin.change}%</td>
                </tr>
                <tr>
                    <td>Profit margin</td>
                    <td className="hc_table__trend"><img src={`/images/trend_${summary.netProfitMargin.trend}.png`} alt="Trend Down" /></td>
                    <td>{summary.netProfitMargin.current}%</td>
                    <td>{summary.netProfitMargin.previous}%</td>
                    <td>{summary.netProfitMargin.change}%</td>
                </tr>
                <tr>
                    <td>Working capital ratio</td>
                    <td className="hc_table__trend"><img src={`/images/trend_${summary.workingCapitalRatio.trend}.png`} alt="Trend Down" /></td>
                    <td>{summary.workingCapitalRatio.current}%</td>
                    <td>{summary.workingCapitalRatio.previous}%</td>
                    <td>{summary.workingCapitalRatio.change}%</td>
                </tr>
            </tbody>
        </table>)
}