import React from 'react';

import './style.scss';

type TProps = {
    showScooter?: boolean;
}

export const Footer = (props: TProps) => {

    return (
        <footer className="footer" style={props.showScooter ? { height: "18rem" } : {}}>
            <div className="copyright">
                <svg className="copyright__logo">
                    <use xlinkHref="/images/banker.svg#footer" />
                </svg>
                <div className="copyright__text">
                    2013-2020 © 9 Spokes International Limited. All rights reserved. 9Spokes, the 9Spokes logo, and the 9Spokes device are all trademarks of 9 Spokes International Limited
          </div>
            </div>
            {
                props.showScooter && <img src="/images/scooter.png" className="footer__scooter" alt="Scooter" />
            }

        </footer>
    )
}