import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { TBookmark, TClient, TConnection, TGraphData, TSearch, TCompany, TUser, TSummary } from '../types';

export const instance = axios.create({
    withCredentials: true,
});

instance.interceptors.response.use(
    (resp: AxiosResponse<any>) => {
        if (resp.data.status && resp.data.details) {
            if (resp.data.status === 'ok') return resp.data.details;
            else return Promise.reject('An API error has occured');
        } else {
            return resp.data;
        }
    },
    (error: AxiosError) => {
        if (error.response) {
            console.log(error);
            return Promise.reject(error);
        }
    }
);

const root = process.env.REACT_APP_API_ROOT || "https://mybusinesslive.bnz.co.nz/monitor/ui";

const API = {
    token: (tok: string) => instance.interceptors.request.use((req: AxiosRequestConfig) => {
        req.headers["authorization"] = `bearer ${tok}`;
        return req;
    }),

    getBookmarks: async (): Promise<TBookmark[]> => {
        const ret: TBookmark[] = await instance.get(`${root}/watchlist`);
        return ret;
    },
    delBookmark: async (id: string): Promise<void> => {
        return await instance.delete(`${root}/watchlist/${id}`);
    },
    addBookmark: async (bookmark: TBookmark): Promise<void> => {
        return await instance.post(`${root}/watchlist`, bookmark);
    },

    getSearches: async (): Promise<TSearch[]> => {
        const ret: TSearch[] = await instance.get(`${root}/searches`);
        return ret;
    },
    addSearch: async (search: TSearch): Promise<void> => {
        return await instance.post(`${root}/searches`, search);
    },

    search: async (q: string): Promise<TClient[]> => {
        try {
            let matches: any[] = await instance.get(`${root}/companies?q=${q}`);
            return matches.map(company => { company.id = company.company; return company })
        } catch (e) {
            console.log(e.message);
            return [];
        }
    },
    getConnections: async (id: string): Promise<TConnection[]> => {
        return await instance.get(`${root}/connections/${id}`);
    },
    getSummary: async (id: string): Promise<TSummary> => {
        return await instance.get(`${root}/companies/${id}/summary`);
    },
    getCompany: async (id: string): Promise<TCompany> => {
        return await instance.get(`${root}/companies/${id}`);
    },
    getUser: async (id: string): Promise<TUser> => {
        return await instance.get(`${root}/users/${id}`);
    },
    getGraph: async (id: string, metric: string): Promise<TGraphData> => {
        return await instance.get(`${root}/companies/${id}/graph?metric=${metric}`);
    },
};

export default API;