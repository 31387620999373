import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { Search } from './pages/search';
import { Bookmarks } from './pages/bookmarks';
import { History } from './pages/history';
import { Company } from './pages/company';
import { Error } from './pages/error';

import { Protect } from './protect';

import './sass/main.scss';

const App = () => {

  return (
    <div className="body">
      <Router>
        <Switch>
          <Route path="/error" render={(props) => <Error {...props} />} />
          <Protect>
            <Switch>
              <Route exact path="/bookmarks" render={(props) => <Bookmarks />} />
              <Route exact path="/search" render={() => <Search />} />
              <Route exact path="/history" render={() => <History />} />
              <Route exact path="/company" render={() => <Company />} />

              {/* <Route exact path="/invite" render={(props) => <Main selected="invite" tabs={tabs} {...props} />} /> */}
              {/* <Route path="/report/:company/:report" render={(props) => <ViewReport {...props} />} /> */}
              <Route exact path="/">
                <Redirect to="/search" />
              </Route>
            </Switch>
          </Protect>
        </Switch>
      </Router>
    </div >
  );
}

export default App;
