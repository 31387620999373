import React from 'react';

type TProps = {
    list: string[];
    open: boolean;
    onChange: Function;
    selected: string;
    onSelect: Function;
}

export const Full = (props: TProps) => {


    return (
        <>
            <span className="dropdown dropdown_full" onClick={() => props.onChange()}  >
                <input className="dropdown_full__input" value={props.selected} type="text" readOnly />
                <svg className="dropdown_full__icon" >
                    <use xlinkHref="/images/banker.svg#angle_down" />
                </svg>
            </span>
            {
                props.open && (
                    <ul className="dropdown_full__content">
                        {
                            props.list.map(item => (<li key={item} className="dropdown_full__item" onClick={() => props.onSelect(item)}>{item}</li>))
                        }
                    </ul>
                )
            }
        </>
    )
}