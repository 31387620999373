import React from 'react';

import { TShareholder } from '../../types';

export const Shareholders = ({ list }: { list: TShareholder[] }) => {

    return (
        <section className="shareholders">
            {
                list.map((shareholder, i) =>
                    <div key={i} className="shareholder">
                        <div className="shareholder__name">
                            <div>Full legal name:</div>
                            <div className="shareholder__value">{shareholder.name}</div>
                        </div>
                        <div className="shareholder__name">
                            <div>Share percentage</div>
                            <div className="shareholder__value">{shareholder.allocationPercent}%</div>
                        </div>
                    </div>
                )
            }
        </section>
    );

}