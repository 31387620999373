import React, { useState, useEffect } from 'react'
import { TGraphData, TClient } from '../../types';
import API from '../../services/api';
import { Chart } from '../chart';

type TGraphs = {
    revenue: TGraphData;
    grossProfitMargin: TGraphData;
    workingCapitalRatio: TGraphData;
};

export const Graph = ({ client }: { client: TClient }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<TGraphs>({ revenue: {}, grossProfitMargin: {}, workingCapitalRatio: {} });

    useEffect(() => {
        Promise.all([
            API.getGraph(client.id, "revenue").then((res) => setData(prev => ({ ...prev, revenue: res }))),
            API.getGraph(client.id, "grossProfitMargin").then((res) => setData(prev => ({ ...prev, grossProfitMargin: res }))),
            API.getGraph(client.id, "workingCapitalRatio").then((res) => setData(prev => ({ ...prev, workingCapitalRatio: res }))),
        ]).then(() => setLoading(false));

    }, [client.id]);

    return (
        <>
            <section className="chart_container">
                <div className="chart_container__heading">Revenue</div>
                <Chart data={data.revenue} loading={loading} id={"revenue"} />
            </section>
            <section className="chart_container">
                <div className="chart_container__heading">Gross Profit Margin</div>
                <Chart data={data.grossProfitMargin} loading={loading} id={"grossProfitMargin"} />
            </section>
            <section className="chart_container">
                <div className="chart_container__heading">Working Capital Ratio</div>
                <Chart data={data.workingCapitalRatio} loading={loading} id={"workingCapitalRatio"} />
            </section>
        </>
    )
}