import React from 'react';

import './style.scss';

export const NotConnected = () => {

    return (
        <div className="not-connected">
            <img src="/images/disconnect.svg" className="not-connected__image" alt="Disconnected" />
            <div className="not-connected__text">
                This business does not have any accounting apps connected.
            </div>
            <button className="not-connected__request">
                Request connection
            </button>
        </div>
    )
}
