import React, { useState } from 'react';

import { Plain } from './plain';
import { Full } from './full';

import './style.scss';

type TProps = {
    items: string[];
    selected?: string;
    type?: "plain" | "full";
    onClick?: Function;
}

export const Dropdown = (props: TProps) => {

    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>(props.selected || props.items[0]);

    const toggleMenu = () => {
        setOpen(!open);
    }

    const setItem = (item: string) => {
        setSelected(item);
        setOpen(false);
        props.onClick && props.onClick(item);
    }

    return (
        <>
            {props.type !== "plain" && <Full list={props.items} open={open} onChange={toggleMenu} selected={selected} onSelect={setItem} />}
            {props.type === "plain" && <Plain list={props.items} open={open} onChange={toggleMenu} selected={selected} onSelect={setItem} />}
        </>
    )
}