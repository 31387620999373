import React, { useContext } from "react";
import { Link, useHistory } from 'react-router-dom';
import Context from '../../context/context';
import { TClient } from '../../types';

import './style.scss';

type TProps = {
    showBackArrow?: boolean;
    showClose?: boolean;
    showLogo?: boolean;
    showBookmarkButton?: boolean;
    showBookmarkAdd?: TClient;
    title?: string;
}

export const Header = (props: TProps) => {

    const history = useHistory();
    const ctx = useContext(Context);

    const title = (title:string):string => title.length > 35 ? title.slice(0,35)+"...":title;

    return (
        <header className="header">
            {props.showBackArrow && (<button onClick={history.goBack} className="header__back">&lsaquo;</button>)}
            {props.title ? (
                <h1 className="header__title">{title(props.title)}</h1>
            ) : <span className="header__spacer"></span>}
            {props.showBookmarkButton && (
                <Link className="bookmark" to="/bookmarks">
                    <span className="bookmark__text">View bookmarks</span>
                    <img src="/images/bookmark.png" alt="bookmark" className="bookmark__icon" />
                </Link>)}
            {props.showBookmarkAdd && (
                <button className="header__bookmark" onClick={() => ctx.bookmarkAdd(props.showBookmarkAdd)}>
                    <svg>
                        <use href="/images/banker.svg#bookmark" style={ctx.bookmarked(props.showBookmarkAdd.name) ? { fill: "var(--color-primary)" } : {}} />
                    </svg>
                </button>
            )}
        </header>
    );
}