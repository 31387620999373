import React, { useState, useContext } from 'react';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Tabs } from '../../components/tabs';
import Context from '../../context/context';
import { ClientInfo } from '../../components/client_info';
import { HealthCheck } from '../../components/health_check';
import { Redirect } from 'react-router-dom';

import './company.scss';

type TTab = {
    label: string;
    component: JSX.Element;
}

export const Company = () => {

    const [selected, setSelected] = useState(0);
    const ctx = useContext(Context);

    if (ctx.client.id === "") {
        return <Redirect to="/search" />
    }

    const tabs: TTab[] = [
        { label: "Client info", component: <ClientInfo client={ctx.client} /> },
        { label: "Client health check", component: <HealthCheck client={ctx.client} /> },
    ];

    const handleChange = (index: number) => {
        setSelected(index);
    }

    return (
        <>
            <Header showBackArrow={true} title={ctx.client.name || ''} showClose={true} showBookmarkAdd={ctx.client} />
            <Tabs labels={tabs.map(t => t.label)} onChange={handleChange} />
            {tabs[selected].component}
            <Footer />
        </>
    )
}

export default Company;