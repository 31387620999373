import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';

import Context from '../../context/context';
import { TClient } from '../../types';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Loader } from '../../components/loader';

import './style.scss';

export const Search = () => {

    const ctx = useContext(Context);
    const history = useHistory();

    const handleSearch = async (q: any) => {
        await ctx.search(q);
    }

    const handleChange = (item: any) => {
        ctx.searchesAdd(item[0]);
        history.push(`/company`);
        ctx.clientSet(item[0]);
    }

    const options = ctx.companies.map((i: TClient) => ({
        label: i.name,
        name: i.name,
        id: i.company,
    }));

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        ctx.searchesGet()
            .then(() => setLoading(false));
    }, []);

    return (
        <>
            <Header showLogo={true} showBookmarkButton={true} showClose={true} />

            <main className="main">
                <h1 className="main__heading">Client search</h1>
                <p className="main__content">Please enter your client's business name in the search bar below.
                </p>

                <form className="search">
                    <AsyncTypeahead
                        id="search__input"
                        isLoading={false}
                        minLength={2}
                        onSearch={handleSearch}
                        searchText="Searching..."
                        onChange={handleChange}
                        options={options}
                        placeholder="Search"

                        autoFocus
                    />
                </form>

                <section className="recent">
                    <Link to="/history"><h3 className="recent__heading">Recent Searches</h3></Link>
                    <ul>
                        {loading ? <Loader /> :
                            ctx.searches
                                .slice(0, 5)
                                .sort((a, b) => new Date(b.added).getTime() - new Date(a.added).getTime())
                                .map((s, i) => <li key={i} className="recent__entry" onClick={() => ctx.clientSet(s)}>
                                    <Link to={`/company`}>
                                        {s.name}
                                    </Link>
                                </li>)
                        }
                    </ul>
                </section>
            </main>

            <Footer showScooter={true} />
        </>
    );
}
