import React from 'react';
import './loader.scss';

type TProps = {
  size?: "small" | "medium" | "large";
}

export const Loader = (props: TProps) => {

  return (
    <div className="spinner">
      <div className="threes" role="status" aria-hidden="true">
        <div className="threes_three" />
        <div className="threes_three_1" />
        <div className="threes_three_2" />
      </div>
    </div>
  );

}
