import * as d3 from 'd3';

// Arrows
export const upArrowPath = 'M 0,0 L -6,7 L -2.5,7 L -2.5,14 L 2.5,14 L 2.5,7 L 6,7 Z';
export const downArrowPath = 'M 0,14 L -6,7 L -2.5,7 L -2.5,0 L 2.5,0 L 2.5,7 L 6,7 Z';
export const noChangePath = 'M -6,4.5 L -6,9.5 L 6,9.5 L 6,4.5 Z';
export const arrowHeight = 14;
export const arrowWidth = 12;

export function getArrowPath(curr, prev) {
  if (curr > prev) {
    return upArrowPath;
  } else if (curr < prev) {
    return downArrowPath;
  } else {
    return noChangePath;
  }
}

export function getArrowClass(curr, prev) {
  if (curr > prev) {
    return 'up-arrow';
  } else if (curr < prev) {
    return 'down-arrow';
  } else {
    return 'no-change';
  }
}

// Format
export function formatInfoValue(value, type) {
  switch (type) {
    case 'count':
      return d3.format(',d')(value);
    case 'percentage':
      return d3.format('.1%')(value / 100);
    case 'dollar':
      return d3.format('$,d')(value);
    case 'pound':
      return '£' + d3.format(',.2f')(value);
    case 'decimal':
      return d3.format(',.2f')(value);
    case 'decimalpoint1':
      return d3.format(',.1f')(value);
    default:
      return d3.format(',d')(value);
  }
}

export function formatFocusValue(value, type) {
  switch (type) {
    case 'count':
      return d3.format(',d')(value);
    case 'percentage':
      return d3.format('.1%')(value / 100);
    case 'dollar':
      return d3.format('$.3s')(value);
    case 'pound':
      return '£' + d3.format('.3s')(value);
    case 'decimal':
      return d3.format(',.2f')(value);
    case 'decimalpoint1':
      return d3.format(',.1f')(value);
    default:
      return d3.format(',d')(value);
  }
}
