import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Dropdown } from '../../components/dropdown';
import { Loader } from '../../components/loader';

import './style.scss';
import Context from '../../context/context';

export const Bookmarks = () => {

  const ctx = useContext(Context);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    ctx.bookmarkGet()
      .then(() => setLoading(false));
  }, []);

  return (
    <>
      <Header showBackArrow={true} showClose={true} />

      <main className="main">
        <h1 className="main__heading">Bookmarks</h1>
        <p className="main__content">You can bookmark up to 10 clients.<br />
        Below are your bookmarked clients.</p>

        <section className="heading">
          <h3 className="heading__text">Bookmarked clients</h3>

          <form className="heading__history">
            <Dropdown items={["Recently added", "Name"]} onClick={ctx.bookmarkSort} />
          </form>
        </section>

        <section className="bookmarks">
          <ul>
            {loading ? <Loader /> :
              ctx.bookmarks !== undefined && ctx.bookmarks.map((bookmark, i) =>
                <li key={i} className="bookmarks__entry">
                  <button className="bookmarks__btn" onClick={() => ctx.bookmarkDel(bookmark.id)} />
                  <Link to={`/company`} onClick={() => ctx.clientSet(bookmark)}>{bookmark.name}</Link>
                </li>
              )
            }
          </ul>
        </section>

      </main>

      <Footer showScooter={true} />
    </>
  );
}
