import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Dropdown } from '../../components/dropdown';
import  Context from '../../context/context';

import './style.scss';

export const History = () => {

  const ctx = useContext(Context);

  return (
    <>
      <Header showBackArrow={true} showClose={true} />

      <main className="main">
        <h1 className="main__heading">Recent searches</h1>
        <p className="main__content">How many free autoresponders have you tried? Really how many?
        And how many emails did you get through using them? How do you know?
        How many people opened your followup message?
        </p>

        <section className="heading">
          <h3 className="heading__text">Search history</h3>

          <form className="heading__history">
            <Dropdown items={["Most recent", "Name"]} onClick={ctx.searchesSort} />
          </form>
        </section>

        <section className="recent">
          <ul>
            {
              ctx.searches.map((s, i) => <li key={i} className="recent__entry" onClick={() => ctx.clientSet(s)}><Link to={`/company`}>{s.name}</Link></li>)
            }
          </ul>
        </section>

      </main>

      <Footer showScooter={true} />
    </>
  );
}
