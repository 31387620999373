import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as qs from 'querystring';
import * as jwt from 'jsonwebtoken';
import API from './services/api';

export const Protect = (props: any) => {

    const [ token, setToken ] = useState<string>();

    if (token) return props.children;

    if (process.env.REACT_APP_TOKEN) {
        const tok = process.env.REACT_APP_TOKEN;
        setToken(tok as string);
        API.token(tok as string);
        return props.children;
    }

    try {
        const params = qs.parse(props.location.search.substr(1));

        const tok = jwt.verify((params.token) as string, process.env.TEAMS_SECRET || "secret")

        if (tok === undefined) {
            console.log(`Error parsing token`);
            return (<Redirect to="/error?code=Unauthorized" />)
        }

        setToken(params.token as string);
        API.token(params.token as string);

    } catch (e) {
        console.log(`Error parsing token: ${e.message}`);
        return (<Redirect to="/error?code=Unauthorized" />)
    }
    return props.children;

}