import React from 'react';
import qs from "querystring";

import './error.scss';

export const Error = (props: any) => {

    let message = "";
    const params = qs.parse(props.location.search.substr(1));

    switch (params["code"]) {
        case "Unauthorized": message = "Your session timed out, please close this window and try again"; break;
        case "NoAccountingApp": message = "This company does not have any accounting apps connected"; break;
        case "NoApps": message = "This company does not have any apps connected"; break;
        case "UnknownDatasource": message = "No data available for this type of query"; break;
        default: message = "An unknown error has occured, please close this window and try again later"; break;
    }

    return (
        <div className="error">
            <div className="error__container">
                <div className="error__content">{message}</div>
            </div>
        </div>)
}