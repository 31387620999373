import React from 'react';
import { TCompany, TUser } from '../../types';

const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const Summary = ({ company, user }: { company: TCompany | undefined, user: TUser | undefined }) => {

    const title = (title: string): string => title.length > 20 ? title.slice(0, 20) + "..." : title;

    return (
        <>
            <section className="client_number">
                {/* Client Number: {company.bnz} */}
            </section>

            <section className="company_summary">
                <div className="company_details">
                    <div className="company_summary__section">
                        <span className="company_summary__heading">Trading name(s):</span>
                        {company && title(company?.name)}
                    </div>
                    <div className="company_summary__section">
                        <span className="company_summary__heading">Company no:</span>
                        {company?.details?.id}
                    </div>
                    <div className="company_summary__section">
                        <span className="company_summary__heading">NZBN:</span>
                        {company?.details?.id}
                    </div>
                    <div className="company_summary__section">
                        <span className="company_summary__heading">Directors:</span>
                        {company?.details?.directors?.length || 0}
                    </div>
                    <div className="company_summary__section">
                        <span className="company_summary__heading">AR filing month:</span>
                        {company?.details?.annualReturn?.filingMonth ? Months[company?.details?.annualReturn?.filingMonth] : "-"}
                    </div>
                    <div className="company_summary__section">
                        <span className="company_summary__heading">Shareholders:</span>
                        {company?.details?.totalShareholders || 0}
                    </div>
                </div>
                <div className="company_contact">
                    <div className="company_contact__section">
                        <img src="/images/person.png" alt="Person" className="company_contact__icon" />
                        <div className="company_contact__details">
                            <span className="company_summary__heading">Contact name:</span>
                            {user ? `${user.firstname} ${user.lastname}` : "-"}
                        </div>
                    </div>
                    <div className="company_contact__section">
                        <img src="/images/email.png" alt="Email" className="company_contact__icon" />
                        <div className="company_contact__details">
                            <span className="company_summary__heading">Email:</span>
                            {user ? user.email : ""}
                        </div>
                    </div>
                    <div className="company_contact__section">
                        <img src="/images/phone.png" alt="Phone" className="company_contact__icon" />
                        <div className="company_contact__details">
                            <span className="company_summary__heading">Contact number:</span>
                            {company?.phone || ""}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}