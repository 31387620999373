
export type TClient = {
    name: string;
    company?: string;
    id: string;
}

export type TDirector = {
    name: string;
    status: string;
    appointedAt: Date;
}

export type TShareholder = {
    name: string;
    type: "individual" | "other" | "entity";
    allocation: number;
    allocationPercent: number;
}

export type TContact = {
    name: string;
    email: string;
    phone: string;
}

export type TAPIResponse = {
    status: "ok" | "err";
    message?: string;
    details?: any;
}


export type TWatchlist = {
    company: string;
    user: string;
    added: Date;
    industry: string;
    name: string;
    phone_number: string;
}

export type TConnection = {
    osp: string;
    isAccounting: boolean;
    status: string;
    connection: string;
}

export type TGraphData = {
    labels?: string[];
    series?: any[];
    xUnit?: string;
    yUnit?: string;
    yUnit1?: string;
    yUnit2?: string;
    y1Unit?: string;
    y2Unit?: string;
    y1Arrow?: boolean;
    y2Arrow?: boolean;
};

export type TGraphProps = {
    data: TGraphData;
    chartId: string;
    type: string;
    loading?: boolean;
}

export type TBookmark = {
    name: string;
    added: Date;
    id: string;
}

export type TSearch = {
    name: string;
    added: Date;
    id: string;
}
export type TRecord = {
    code: string;
    description: string;
}

export type TUser = {
    user: string;
    firstname: string;
    lastname: string;
    email: string;
    created: Date;
    lastLoggedIn: Date;
    extra: Object;
}

export type TSummaryEntry = {
    current?: string;
    currentVal?: number;
    previous?: string;
    previousVal?: number;
    change?: string;
    changeVal?: number;
    trend?: "up" | "down";
}

export type TSummary = {
    labels: TSummaryEntry;
    revenue: TSummaryEntry;
    expenses: TSummaryEntry;
    grossProfitMargin: TSummaryEntry;
    netProfitMargin: TSummaryEntry;
    workingCapitalRatio: TSummaryEntry;
}

export type TCompany = {
    name: string;
    industry?: string;
    industries?: TRecord[];
    location: {
        country: string,
        timezone: number
    };
    type: TRecord;
    status: TRecord;
    entity?: string;
    phone: string;
    users: string[];
    created: Date;
    updated: Date;
    details?: {
        id: string;
        name: string;
        status: string;
        type: TRecord;
        incorporationDate: TRecord;
        emails: string[];
        addresses?: {
            addressLine1?: string;
            addressLine2?: string;
            addressLine3?: string;
            addressLine4?: string;
            postCode?: string;
            country?: string;
            careOf?: string;
            type?: string;
        }[];
        annualReturn: {
            filingMonth: number;
            lastFiled: Date;
        };
        totalShares: number;
        totalShareholders: number;
        shareAllocation: TShareholder[];
        directors: TDirector[];
        modified: Date;
    }
}
export enum Months {
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
}

