import React from "react";
import Graph from "./chart";
import { Loader } from '../loader';
import { TGraphData } from '../../types';

export const Chart = (props: { loading?: boolean, data: TGraphData, id: string }) => {

    if (props.loading || !props?.data?.series?.length) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", marginTop: "10rem" }}>
                <Loader size="large" />
            </div>
        )
    }

    return <Graph chartId={props.id} type="area" {...props} />
}