import React from 'react';

type TProps = {
    list: string[];
    open: boolean;
    onChange: Function;
    onSelect: Function;
    selected?: string;
}

export const Plain = (props: TProps) => {

    return (
        <>
            <div className="dropdown dropdown_plain" onClick={() => props.onChange()}><span>{props.selected || props.list[0]}</span>
                <img src="/images/angle_down.svg" alt="Dropdown" className="dropdown_plain__arrow" />
            </div>
            {
                props.open && (

                    <ul className="dropdown_plain__content">
                        {
                            props.list.map(item => (<li key={item} className="dropdown_plain__item" onClick={() => props.onSelect(item)}>{item}</li>))
                        }
                    </ul>
                )
            }
        </>
    )



}